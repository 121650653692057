import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './stylesheets/index.css';

const container = document.getElementById('app');
if (!container) {
	throw new Error('No container found');
}
const root = createRoot(container);

root.render(<App />);
