import React, { useState, useEffect, useContext } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import BackButton from './BackButton';
import NoCodeModal from './NoCodeModal';
import { motion } from 'framer-motion';
import { NonceContext } from './NonceProvider';
import { decrypt, getMasterKey } from './Encryption';

const messageCollection = collection(db, 'messages');

function RetrieveInput() {
	const nonce = useContext(NonceContext);
	const [retrievalCode, setRetrievalCode] = useState('');
	const [message, setMessage] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [mnfText, setMnfText] = useState(false);
	const [showForm, setShowForm] = useState(true);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const retrievalCodeFromUrl = urlParams.get('msg');
		if (retrievalCodeFromUrl) {
			setRetrievalCode(retrievalCodeFromUrl);
		}
	}, []);

	const handleRetrieveMessage = async (e) => {
		e.preventDefault();
		if (retrievalCode === '') {
			setShowModal(true);
			setShowMessage(false);
		} else if (retrievalCode.length !== 44) {
			setShowModal(true);
			setShowMessage(false);
		} else {
			const q = query(
				messageCollection,
				where('RC', '==', retrievalCode)
			);
			getDocs(q)
				.then(async (querySnapshot) => {
					if (querySnapshot.empty) {
						setMnfText(true);
						setShowMessage(false);
					} else {
						querySnapshot.forEach(async (doc) => {
							const encryptedData = doc.data().text;
							const iv = doc.data().iv;

							const base64ToArrayBuffer = (base64) => {
								const binaryString = atob(base64);
								const len = binaryString.length;
								const bytes = new Uint8Array(len);
								for (let i = 0; i < len; i++) {
									bytes[i] = binaryString.charCodeAt(i);
								}
								return bytes.buffer;
							};

							const decodedEncryptedData =
								base64ToArrayBuffer(encryptedData);
							const decodedIv = base64ToArrayBuffer(iv);

							try {
								const masterKey = await getMasterKey();
								if (!masterKey) {
									console.error(
										'Master key retrieval failed.'
									);
									return;
								}

								const decryptedMessage = await decrypt(
									masterKey,
									decodedEncryptedData,
									decodedIv
								);
								setMessage(decryptedMessage);
							} catch (error) {
								console.error(
									'Error decrypting message:',
									error
								);
								setMessage('Error decrypting message');
							}
						});
						setShowForm(false);
					}
				})
				.catch((error) => {
					console.error('Error retrieving message:', error);
					setMessage('Error retrieving message');
				});
		}
	};

	const handleDoneClick = () => {
		window.location.href = '/';
	};

	const handleShowMessage = () => {
		setShowMessage(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	return (
		<>
			<BackButton nonce={nonce} />
			{showForm && (
				<form
					className='retrieve-form'
					onSubmit={handleRetrieveMessage}
					nonce={nonce}
				>
					<motion.input
						className='retrieve-input'
						placeholder='Enter Code'
						value={retrievalCode}
						onChange={(e) => setRetrievalCode(e.target.value)}
						initial={{ opacity: 0, x: -200, y: -200 }}
						animate={{ opacity: 1, x: 0, y: 0 }}
						transition={{
							ease: 'easeOut',
							duration: 0.5,
						}}
						nonce={nonce}
					/>
					<motion.button
						className='retrieve-button'
						type='submit'
						onClick={handleShowMessage}
						initial={{ opacity: 0, x: 200, y: 200 }}
						animate={{ opacity: 1, x: 0, y: 0 }}
						transition={{
							ease: 'easeOut',
							duration: 0.5,
						}}
						nonce={nonce}
					>
						Retrieve
					</motion.button>
				</form>
			)}
			{showMessage && (
				<div className='message-display' nonce={nonce}>
					{message && <div nonce={nonce}>{message}</div>}
				</div>
			)}
			{showMessage && (
				<motion.button
					initial={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{
						ease: 'easeOut',
						duration: 0.5,
					}}
					onClick={handleDoneClick}
					className='message-done submit-button'
					nonce={nonce}
				>
					{'Done'}
				</motion.button>
			)}
			{showModal && <NoCodeModal onClose={handleCloseModal} />}
		</>
	);
}

export default RetrieveInput;
