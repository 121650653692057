// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyC01LsbD4v1a_NWatuZSY9xvVS7RWPh9XY',
	authDomain: 'lettler-cb977.firebaseapp.com',
	projectId: 'lettler-cb977',
	storageBucket: 'lettler-cb977.appspot.com',
	messagingSenderId: '178981094680',
	appId: '1:178981094680:web:f01c7a5aed73ef83a8ca6d',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };
