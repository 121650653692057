export const buttonVariants = {
	rest: {
		backgroundColor: '#000000',
		opacity: 1,
	},
	hover: {
		backgroundColor: '#444444',
		opacity: 1,
	},
	pressed: {
		backgroundColor: '#bdbdbd',
		opacity: 0,
		transition: {
			type: 'tween',
			duration: 1,
			delay: 1,
		},
	},
};

// export const colors = [
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// 	'#6b8bfe',
// 	'#de6bfe',
// 	'#fe6b8b',
// 	'#8bfe6b',
// 	'#6bfede',
// ];

// const colors = [
// 	['#fe6b8b 30%', '#ff8e53 90%', '#fe6b8b'],
// 	['#ff8e53 30%', '#fe6b8b 90%', '#ff8e53'],
// 	['#fe6b8b 30%', '#8bfe6b 90%', '#fe6b8b'],
// 	['#8bfe6b 30%', '#6bfede 90%', '#8bfe6b'],
// 	['#6bfede 30%', '#6b8bfe 90%', '#6bfede'],
// 	['#6b8bfe 30%', '#de6bfe 90%', '#6b8bfe'],
// ];

// const colors = [
// 	['#fe6b8b 30%', '#ff8e53 90%', '#fe6b8b'],
// 	['#ff8e53 30%', '#fe6b8b 90%', '#ff8e53'],
// 	['#fe6b8b 30%', '#8bfe6b 90%', '#fe6b8b'],
// 	['#8bfe6b 30%', '#6bfede 90%', '#8bfe6b'],
// 	['#6bfede 30%', '#6b8bfe 90%', '#6bfede'],
// 	['#6b8bfe 30%', '#de6bfe 90%', '#6b8bfe'],
// 	['#de6bfe 30%', '#fe6b8b 90%', '#de6bfe'],
// 	['#fe6b8b 30%', '#ff8e53 90%', '#fe6b8b'],
// 	['#ff8e53 30%', '#fe6b8b 90%', '#ff8e53'],
// 	['#fe6b8b 30%', '#8bfe6b 90%', '#fe6b8b'],
// 	['#8bfe6b 30%', '#6bfede 90%', '#8bfe6b'],
// 	['#6bfede 30%', '#6b8bfe 90%', '#6bfede'],
// 	['#6b8bfe 30%', '#de6bfe 90%', '#6b8bfe'],
// 	['#de6bfe 30%', '#fe6b8b 90%', '#de6bfe'],
// 	['#fe6b8b 30%', '#ff8e53 90%', '#fe6b8b'],
// 	['#ff8e53 30%', '#fe6b8b 90%', '#ff8e53'],
// 	['#fe6b8b 30%', '#8bfe6b 90%', '#fe6b8b'],
// 	['#8bfe6b 30%', '#6bfede 90%', '#8bfe6b'],
// 	['#6bfede 30%', '#6b8bfe 90%', '#6bfede'],
// 	['#6b8bfe 30%', '#de6bfe 90%', '#6b8bfe'],
// ];

const colors = [
	['#fe6b8b', '#ff8e53'],
	['#ff8e53', '#fe6b8b'],
	['#fe6b8b', '#8bfe6b'],
	['#8bfe6b', '#6bfede'],
	['#6bfede', '#6b8bfe'],
	['#6b8bfe', '#de6bfe'],
	['#de6bfe', '#fe6b8b'],
	['#fe6b8b', '#ff8e53'],
	['#ff8e53', '#fe6b8b'],
	['#fe6b8b', '#8bfe6b'],
	['#8bfe6b', '#6bfede'],
	['#6bfede', '#6b8bfe'],
	['#6b8bfe', '#de6bfe'],
	['#de6bfe', '#fe6b8b'],
	['#fe6b8b', '#ff8e53'],
	['#ff8e53', '#fe6b8b'],
	['#fe6b8b', '#8bfe6b'],
	['#8bfe6b', '#6bfede'],
	['#6bfede', '#6b8bfe'],
	['#6b8bfe', '#de6bfe'],
];

const generateLinearGradient = (colors, direction) => {
	const colorStr = colors.join(', ');
	return `linear-gradient(${direction}, ${colorStr})`;
};
export const gradientValues = colors.map((color) =>
	generateLinearGradient(color, '45deg')
);
