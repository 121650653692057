import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { NonceContext } from './NonceProvider';

function BackButton() {
	const nonce = useContext(NonceContext);
	const handleRefresh = () => {
		window.location.href = window.location.href.split('?')[0];
	};
	return (
		<>
			<motion.div
				className='back-button'
				onClick={handleRefresh}
				initial={{ scale: 0 }}
				animate={{ rotate: 360, scale: 1 }}
				transition={{
					type: 'spring',
					stiffness: 260,
					damping: 20,
				}}
				nonce={nonce}
			>
				{'Back'}
			</motion.div>
		</>
	);
}

export default BackButton;
