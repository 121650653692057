import { useState, useRef, useContext } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import NoMessageModal from './NoMessageModal';
import { generateCode } from '../codeGenerator';
import BackButton from './BackButton';
import { motion } from 'framer-motion';
import { NonceContext } from './NonceProvider';
import { encrypt, getMasterKey } from './Encryption';

const messageCollection = collection(db, 'messages');

function MessageInput() {
	const nonce = useContext(NonceContext);
	const [message, setMessage] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [showCode, setShowCode] = useState(false);
	const [generatedCode, setGeneratedCode] = useState('');

	const textRef = useRef(null);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (message === '') {
			setShowModal(true);
		} else {
			const masterKey = await getMasterKey();

			if (!masterKey) {
				console.error('Master key retrieval failed.');
				return;
			}

			const { iv, encryptedData } = await encrypt(message, masterKey);

			const arrayBufferToBase64 = (buffer) => {
				const bytes = new Uint8Array(buffer);
				let binary = '';
				bytes.forEach((byte) => (binary += String.fromCharCode(byte)));
				return btoa(binary);
			};

			const encryptedDataBase64 = arrayBufferToBase64(encryptedData);
			const ivBase64 = arrayBufferToBase64(iv);

			const newMessage = {
				text: encryptedDataBase64,
				iv: ivBase64,
				createdAt: new Date(),
				RC: generateCode(),
			};

			addDoc(messageCollection, newMessage)
				.then((docRef) => {
					console.log('Document written with ID: ', docRef.id);
					setShowCode(true);
					setGeneratedCode(newMessage.RC);
				})
				.catch((error) => {
					console.error('Error adding document: ', error);
				});
			setMessage('');
		}
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	return (
		<>
			<BackButton nonce={nonce} />
			<form
				className='message-form'
				onSubmit={handleSubmit}
				nonce={nonce}
			>
				{!showModal && (
					<motion.textarea
						maxLength={1000}
						className='message-input'
						placeholder='Enter a message (max 1000 characters)'
						rows={20}
						cols={40}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{
							type: 'spring',
							stiffness: 100,
							duration: 0.5,
						}}
						nonce={nonce}
					/>
				)}
				{!showModal && (
					<motion.button
						className='submit-button'
						type='submit'
						initial={{ opacity: 0, y: -50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{
							type: 'spring',
							stiffness: 100,
							duration: 0.5,
						}}
						nonce={nonce}
					>
						Store Message
					</motion.button>
				)}
			</form>
			{showModal && (
				<NoMessageModal onClose={handleCloseModal} nonce={nonce} />
			)}
			{showCode && (
				<motion.button
					whileTap={{ scale: 0.9 }}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						type: 'spring',
						stiffness: 100,
						duration: 0.2,
					}}
					ref={textRef}
					className='code-display'
					onClick={() => {
						const text = textRef.current.innerText;
						navigator.clipboard.writeText(text);
					}}
					nonce={nonce}
				>
					{generatedCode}
				</motion.button>
			)}
			{showCode && (
				<motion.button
					whileTap={{ scale: 0.9 }}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						type: 'spring',
						stiffness: 100,
						duration: 0.2,
					}}
					className='share-button'
					onClick={() => {
						const shareText = `https://lettler.com/?msg=${generatedCode}`;
						navigator.clipboard.writeText(shareText);
					}}
					nonce={nonce}
				>
					Share With Link
				</motion.button>
			)}
		</>
	);
}

export default MessageInput;
