import { NonceProvider } from '../src/components/NonceProvider';
import React, { useEffect, useState } from 'react';
import MessageInput from './components/MessageInput';
import './stylesheets/App.css';
import IntroButtons from './components/IntroButtons';
import RetrieveInput from './components/RetrieveInput';
import { motion } from 'framer-motion';
import { gradientValues } from './animation';
import TitleDefinition from './components/TitleDefinition';

function App() {
	const [messageMode, setMessageMode] = useState(false);
	const [retrieveMode, setRetrieveMode] = useState(false);
	const [introMode, setIntroMode] = useState(true);

	const handleStoreSelection = (e) => {
		e.preventDefault();
		setIntroMode(false);
		setRetrieveMode(false);
		setMessageMode(true);
	};

	const handleRetrieveSelection = (e) => {
		e.preventDefault();
		setIntroMode(false);
		setMessageMode(false);
		setRetrieveMode(true);
	};

	useEffect(() => {
		handleLink();
	}, []);

	function handleLink() {
		const url = window.location.href;
		if (url.includes('?msg=LTLR-')) {
			setIntroMode(false);
			setMessageMode(false);
			setRetrieveMode(true);
		}
	}

	return (
		<NonceProvider>
			<motion.div
				className='App'
				animate={{ background: gradientValues }} // animate through the array of colors
				transition={{
					duration: 200, // duration of each color transition
					repeat: Infinity, // repeat the animation infinitely
					repeatDelay: 0, // delay between each repeat of the animation
				}}
			>
				{introMode && <TitleDefinition />}
				<motion.h1
					className='title-header'
					initial={{ opacity: 0, y: -50 }}
					animate={{ opacity: 1, y: 10 }}
					transition={{ type: 'spring', stiffness: 100, duration: 0.5 }}
				>
					{'Lettler'}
				</motion.h1>
				{introMode && (
					<IntroButtons
						store={handleStoreSelection}
						retrieve={handleRetrieveSelection}
					/>
				)}
				{messageMode && <MessageInput />}
				{retrieveMode && <RetrieveInput />}
			</motion.div>
		</NonceProvider>
	);
}

export default App;
