import React, { useContext } from 'react';
import { buttonVariants } from '../animation';
import { motion } from 'framer-motion';
import { NonceContext } from './NonceProvider';

function IntroButtons({ store, retrieve }) {
	const nonce = useContext(NonceContext);
	return (
		<>
			<form className='intro-button-form' nonce={nonce}>
				<motion.button
					variants={buttonVariants}
					whileHover='hover'
					whileTap='pressed'
					className='intro-button'
					type='submit'
					onClick={store}
					nonce={nonce}
				>
					{'Store Message'}
				</motion.button>
				<motion.button
					variants={buttonVariants}
					whileHover='hover'
					whileTap='pressed'
					className='intro-button'
					type='submit'
					onClick={retrieve}
					nonce={nonce}
				>
					{'Retrieve Message'}
				</motion.button>
			</form>
		</>
	);
}

export default IntroButtons;
