import React, { createContext, useState, useEffect } from 'react';

const NonceContext = createContext(null);

function NonceProvider(props) {
	const [nonce, setNonce] = useState('');
	const [csp, setCsp] = useState('');

	useEffect((props) => {
		async function generateNonce() {
			const response = await fetch(
				'https://www.random.org/strings/?num=1&len=20&digits=on&loweralpha=on&unique=on&format=plain&rnd=new'
			);
			if (response.ok) {
				const randomString = await response.text();
				setNonce(randomString.trim());
				setCsp(
					`default-src 'none'; manifest-src 'self'; script-src 'self' https://www.gstatic.com/firebasejs/9.17.1/firebase-app.js https://www.gstatic.com/firebasejs/9.17.1/firebase-firestore.js 'nonce-${randomString.trim()}'; style-src 'self' https://fonts.googleapis.com/ 'nonce-${randomString.trim()}'; font-src 'self' https://fonts.gstatic.com/; img-src 'self'; connect-src 'self' https://firestore.googleapis.com/ https://firebasestorage.googleapis.com/; frame-src 'none'; object-src 'none'; base-uri 'none'; form-action 'none';`
				);

				addMetaTag(
					`default-src 'none'; script-src 'self' https://www.gstatic.com/firebasejs/9.17.1/firebase-app.js https://www.gstatic.com/firebasejs/9.17.1/firebase-firestore.js 'nonce-${randomString.trim()}'; style-src 'self' https://fonts.googleapis.com/ 'nonce-${randomString.trim()}'; font-src 'self' https://fonts.gstatic.com/; img-src 'self'; connect-src 'self' https://firestore.googleapis.com/ https://firebasestorage.googleapis.com/; frame-src 'none'; object-src 'none'; base-uri 'none'; form-action 'none';`
				);
			} else {
				console.error(
					`Failed to fetch nonce: ${response.status} ${response.statusText}`
				);
			}
		}
		generateNonce();
	}, []);

	function addMetaTag(cspContent) {
		const meta = document.createElement('meta');
		meta.setAttribute('http-equiv', 'Content-Security-Policy');
		meta.content = cspContent;
		const firstMeta = document.querySelector('meta');
		document.head.insertBefore(meta, firstMeta);
	}

	const contextValue = { nonce, csp };

	return (
		<NonceContext.Provider value={contextValue}>
			{props.children}
		</NonceContext.Provider>
	);
}

export { NonceContext, NonceProvider };
