import React, { useContext } from 'react';
import { NonceContext } from './NonceProvider';

function TitleDefinition() {
	const nonce = useContext(NonceContext);
	return (
		<>
			<div className='title-definition' nonce={nonce}>
				<h3 className='title-definition-text' nonce={nonce}>
					{
						'A digital vault for storing and retrieving small messages anonymously. The messages can only be accessed with a unique code, and if the code is lost, the message is gone forever. Have fun with it and good luck!'
					}
				</h3>
			</div>
		</>
	);
}

export default TitleDefinition;
