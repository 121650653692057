import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { NonceContext } from './NonceProvider';

function NoCodeModal({ onClose }) {
	const nonce = useContext(NonceContext);
	return (
		<>
			<motion.div
				className='modal'
				initial={{ width: 0 }}
				animate={{ width: '60vw' }}
				transition={{ type: 'tween', stiffness: 500, duration: 0.2 }}
				nonce={nonce}
			>
				<div className='modal-content' nonce={nonce}>
					<h2 className='modal-text code' nonce={nonce}>
						{'No Code Entered'}
					</h2>
					<p className='modal-text code' nonce={nonce}>
						{'You must enter a code to retrieve a message'}
					</p>
					<button className='close-button' onClick={onClose} nonce={nonce}>
						{'OK'}
					</button>
				</div>
			</motion.div>
		</>
	);
}

export default NoCodeModal;
