export function generateCode() {
	const date = new Date(Date.now()).toUTCString();
	const parts = date.split(' ');

	const year = parts[3].substring(2);
	const month = (
		'0' +
		(new Date(
			parts[2] + ' ' + parts[1] + ', ' + parts[parts.length - 1]
		).getUTCMonth() +
			1)
	).slice(-2);
	const day = (
		'0' +
		new Date(
			parts[2] + ' ' + parts[1] + ', ' + parts[parts.length - 1]
		).getUTCDate()
	).slice(-2);

	const random1 = Math.floor(Math.random() * 100000)
		.toString()
		.padStart(5, '0');
	const random2 = Math.floor(Math.random() * 100000)
		.toString()
		.padStart(5, '0');
	const random3 = Math.floor(Math.random() * 100000)
		.toString()
		.padStart(5, '0');

	const randomChars1 = Array.from({ length: 3 }, () =>
		String.fromCharCode(65 + Math.floor(Math.random() * 26))
	).join(Math.floor(Math.random() * 9));
	const randomChars2 = Array.from({ length: 3 }, () =>
		String.fromCharCode(65 + Math.floor(Math.random() * 26))
	).join(Math.floor(Math.random() * 9));
	const randomChars3 = Array.from({ length: 3 }, () =>
		String.fromCharCode(65 + Math.floor(Math.random() * 26))
	).join(Math.floor(Math.random() * 9));

	return `LTLR-${randomChars1}${random1}-${year}${month}${day}-${randomChars2}${random2}-${randomChars3}${random3}`;
}

export async function getRandomString() {
	const response = await fetch(
		'https://www.random.org/strings/?num=1&len=20&digits=on&loweralpha=on&unique=on&format=plain&rnd=new'
	);
	if (response.ok) {
		const randomString = await response.text();
		return randomString.trim();
	} else {
		throw new Error(
			`Failed to fetch random string: ${response.status} ${response.statusText}`
		);
	}
}
