import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

export async function generateKey() {
	const key = await crypto.subtle.generateKey(
		{
			name: 'AES-GCM',
			length: 256,
		},
		true,
		['encrypt', 'decrypt']
	);
	return key;
}

export async function encrypt(plainText, key) {
	const iv = crypto.getRandomValues(new Uint8Array(12));
	const encoder = new TextEncoder();
	const data = encoder.encode(plainText);
	const encryptedData = await crypto.subtle.encrypt(
		{
			name: 'AES-GCM',
			iv: iv,
		},
		key,
		data
	);
	return { iv, encryptedData };
}

export async function decrypt(key, encryptedData, iv) {
	try {
		const decryptedArrayBuffer = await crypto.subtle.decrypt(
			{
				name: 'AES-GCM',
				iv: iv,
			},
			key,
			encryptedData
		);
		const decryptedText = new TextDecoder().decode(decryptedArrayBuffer);
		return decryptedText;
	} catch (error) {
		console.error('Error decrypting message:', error);
		throw error;
	}
}

export async function getMasterKey() {
	const chunksDocRef = doc(db, 'chunks', 'dU0qfpRER9isIBBixbBk');
	const chunksDocSnapshot = await getDoc(chunksDocRef);

	const chunk1 = chunksDocSnapshot.get('chunk1');
	const chunk2 = chunksDocSnapshot.get('chunk2');

	const masterKeyString = chunk1 + chunk2;

	if (masterKeyString.length !== 32) {
		console.error(
			'Master key string length is incorrect:',
			masterKeyString.length
		);
		return;
	}

	const masterKeyArray = new Uint8Array(32);
	for (let i = 0; i < masterKeyString.length; i++) {
		masterKeyArray[i] = masterKeyString.charCodeAt(i);
	}

	try {
		const masterKey = await crypto.subtle.importKey(
			'raw',
			masterKeyArray.buffer,
			'AES-GCM',
			false,
			['encrypt', 'decrypt']
		);
		return masterKey;
	} catch (error) {
		console.error('Error during key import:', error);
		return;
	}
}
